import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, TextField, Button, MenuItem, Typography, Divider,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid
} from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';


const formatDate = (date) => date.toISOString().split('T')[0];

const EditQuotation = ({ data, onCustomerUpdated }) => {
  const [formData, setFormData] = useState({
    quotation_no: '',
    customer_id: '',
    status: '',   
    status_date: new Date().toISOString().split('T')[0],
    net_price: '',
    tax: '',
    total: '',
    tax_rate: '',
    is_tax: '',
    remarks: '',
    date: formatDate(new Date()),
  });
  const [tax, setTax] = useState({
    name: '',
    tax_rate: ''
  });
  const [services, setServices] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [newService, setNewService] = useState({ id: '', quantity: 1 });
  const [newItem, setNewItem] = useState({ id: '', quantity: 1 });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [registeredCustomers, setRegisteredCustomers] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchQuotationData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/quotation/QuotationNo/${data.quotation_no}`);
        setFormData(res.data[0]);
        const resItems = await axios.get(`${BASE_URL}/quotation/QuotationNoItem/${data.quotation_no}`);
        setSelectedServices(resItems.data.map(item => ({
          id: item.item_id,
          name: item.item,
          quantity: item.qty,
          price: item.unit_price,
          total_price: item.total_price,
        })));
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    };
    fetchQuotationData();
  }, [data.quotation_no]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const serviceRes = await axios.get(`${BASE_URL}/item/AllService`);
        const itemRes = await axios.get(`${BASE_URL}/item/AllItem`);
        setServices(serviceRes.data.map(service => ({
          id: service.iditem,
          unit: service.unit_name,
          name: service.name,
          price: service.price,
        })));
  
        setItems(itemRes.data.map(item => ({
          id: item.iditem,
          unit: item.unit_name,
          name: item.name,
          price: item.price,
        })));
        const taxRes = await axios.get(`${BASE_URL}/quotation/tax`);
        setTax(taxRes.data);
        const customerRes = await axios.get(`${BASE_URL}/customer/Allcustomers`);
        setRegisteredCustomers(customerRes.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleServiceChange = (e) => {
    const { name, value } = e.target;
    setNewService({
      ...newService,
      [name]: value,
    });
  };

  const handleItemChange = (e) => {
    const { name, value } = e.target;
    setNewItem({
      ...newItem,
      [name]: value,
    });
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    setNewService({
      ...newService,
      [name]: value,
    });
  };

  const handleItemPriceChange = (e) => {
    const { name, value } = e.target;
    setNewItem({
      ...newItem,
      [name]: value,
    });
  };

  const handleRegisteredCustomerChange = async (e) => {
    const customerId = e.target.value;
    const customer = registeredCustomers.find(c => c.idcustomer === customerId);

    if (customer) {
      try {
        setFormData({
          ...formData,
          customer_id: customer.idcustomer,
          name: customer.name,
          email: customer.email_address,
          phone: customer.mobile,
          address: customer.address,
          remarks: '',
        });
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const addService = () => {
    const service = services.find(s => s.id === parseInt(newService.id, 10));
    if (service) {
      setSelectedServices([
        ...selectedServices,
        { ...service, quantity: newService.quantity, total_price: service.price * newService.quantity },
      ]);
      setNewService({ id: '', quantity: 1 });
    }
  };

  const addItem = () => {
    const item = items.find(i => i.id === parseInt(newItem.id, 10));
    if (item) {
      setSelectedItems([
        ...selectedItems,
        { ...item, quantity: newItem.quantity, total_price: item.price * newItem.quantity },
      ]);
      setNewItem({ id: '', quantity: 1 });
    }
  };

  const removeService = (index) => {
    const updatedServices = selectedServices.filter((_, i) => i !== index);
    setSelectedServices(updatedServices);
  };

  const removeItem = (index) => {
    const updatedItems = selectedItems.filter((_, i) => i !== index);
    setSelectedItems(updatedItems);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const responseHeader = await axios.post(`${BASE_URL}/quotation/UpdateHeader`, {
        quotation_no: formData.quotation_no,
        customer_id: formData.customer_id,
        status: formData.status,      
        status_date: formData.status_date,
        tax: calculateTax(calculateTotal(selectedServices)),
        net_price: calculateTotal(selectedServices),
        total: (parseFloat(calculateTotal(selectedServices)) + parseFloat(calculateTax(calculateTotal(selectedServices)))).toFixed(2),        
        is_tax: formData.is_tax,
        remarks: formData.remarks,
        date: formData.date,
      });

      const quotation_no = responseHeader.data[0];
      const detailsData = selectedServices.map(service => ({
        quotation_no: quotation_no,
        item_id: service.id,
        item: service.name,
        quantity: service.quantity,
        price: service.price,
        total_price: service.quantity * service.price,
      }));
      
      if (quotation_no !== '') {
        await axios.delete(`${BASE_URL}/quotation/DeleteDetails/${quotation_no}` );
        await axios.post(`${BASE_URL}/quotation/SaveDetails`, { detailsData });
      }

      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const calculateTotal = (services = []) => {
    return services.reduce((total, service) => total + (service.price * service.quantity), 0).toFixed(2);
  };

  const calculateTax = (total) => {
    return (total * tax[0].tax_rate).toFixed(2);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        Edit
      </MDButton>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            <form onSubmit={handleSubmit}>
              <Typography variant="h4" gutterBottom>
                Quotation
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <TextField
                    label="Select Customer"
                    name="customerId"
                    select
                    value={formData.customer_id || ''}
                    onChange={handleRegisteredCustomerChange}
                    fullWidth
                    margin="normal"
                    required
                  >
                    <MenuItem value="">
                      <em>Select a customer</em>
                    </MenuItem>
                    {registeredCustomers.map((customer) => (
                      <MenuItem key={customer.idcustomer} value={customer.idcustomer}>
                       {customer.name} | Address:{customer.address}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
               
              
                <Grid item xs={5}>
                  <TextField
                    label="Date"
                    name="date"
                    type="date"
                    value={
                      formData.date
                        ? new Date(formData.date).toISOString().split('T')[0] // Convert to yyyy-mm-dd
                        : ''
                    }
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Quotation No"
                    name="quotation_no"
                    value={formData.quotation_no}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Add Services
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    label="Service"
                    name="id"
                    select
                    value={newService.id}
                    onChange={handleServiceChange}
                    fullWidth
                    margin="normal"

                  >
                    <MenuItem value="">
                      <em>Select a service</em>
                    </MenuItem>
                    {services.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                       {service.name} | {service.unit}:1 | €:{service.price}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Quantity"
                    name="quantity"
                    type="number"
                    value={newService.quantity}
                    onChange={handleServiceChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
           
                <Grid item xs={12}>
                  <MDButton variant="contained" color="success" onClick={addService} fullWidth>
                    Add Service
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Add Equipments
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    label="Equipments"
                    name="id"
                    select
                    value={newItem.id}
                    onChange={handleItemChange}
                    fullWidth
                    margin="normal"
                 
                  >
                    <MenuItem value="">
                      <em>Select an Equipments</em>
                    </MenuItem>
                    {items.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name} | {item.unit}:1 | €:{item.price}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Quantity"
                    name="quantity"
                    type="number"
                    value={newItem.quantity}
                    onChange={handleItemChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
              
                <Grid item xs={12}>
                  <MDButton variant="contained" color="success" onClick={addItem} fullWidth>
                    Add Equipments
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                       
                      </TableHead>
                      <TableBody>
                      <TableRow>
                          <TableCell style={{ width: '30%' }}>Description</TableCell>
                          <TableCell style={{ width: '10%' }} align="right">Unit</TableCell>
                          <TableCell style={{ width: '10%' }} align="right">Quantity</TableCell>
                          <TableCell style={{ width: '20%' }} align="right">Price per Unit</TableCell>
                          <TableCell style={{ width: '20%' }} align="right">Total Price</TableCell>
                          <TableCell style={{ width: '10%' }} align="right"></TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell style={{ width: '30%', backgroundColor: '#ffecf2', color: '#333' }}>Service</TableCell>                      
                        </TableRow>
                        {selectedServices.map((service, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {service.name}
                            </TableCell>
                            <TableCell align="right">{service.unit}</TableCell>
                            <TableCell align="right">{service.quantity}</TableCell>
                            <TableCell align="right">€ {service.price}</TableCell>
                            <TableCell align="right">€ {service.price * service.quantity}</TableCell>
                            <TableCell align="right">
                              <Button variant="contained" color="success" onClick={() => removeService(index)}>
                                X
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                        <TableCell style={{ width: '30%', backgroundColor: '#f3eeff', color: '#333' }}>Equipments</TableCell>                        
                        </TableRow>
                        {selectedItems.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {item.name}
                            </TableCell>
                            <TableCell align="right">{item.unit}</TableCell>
                            <TableCell align="right">{item.quantity}</TableCell>
                            <TableCell align="right">€ {item.price}</TableCell>
                            <TableCell align="right">€ {item.price * item.quantity}</TableCell>
                            <TableCell align="right">
                              <Button variant="contained" color="success" onClick={() => removeItem(index)}>
                                X
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell rowSpan={3} />
                          <TableCell colSpan={3}>Net</TableCell>
                          <TableCell align="right">€ {calculateTotal([...selectedServices, ...selectedItems])}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3}>{tax.name} {(tax.tax_rate * 100).toFixed(2)}%</TableCell>
                          <TableCell align="right">€ {calculateTax(calculateTotal([...selectedServices, ...selectedItems]))}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3}>Total</TableCell>
                          <TableCell align="right">€ {(parseFloat(calculateTotal([...selectedServices, ...selectedItems])) + parseFloat(calculateTax(calculateTotal([...selectedServices, ...selectedItems])))).toFixed(2)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <MDButton type="submit" variant="contained" color="success" fullWidth>
                    Save Quotation
                  </MDButton>
                </Grid>
              </Grid>
            </form>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditQuotation;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Divider, Grid, MenuItem, Typography } from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const EditEmployee = ({ data, onCustomerUpdated }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    Mobile: 0,
    phone: 0,
    address: '',
    passport: '',
    nic: '',
    designation_id: '',
    employe_type_id: '',
    idemployee: '',
    status:0,
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [formError, setFormError] = useState('');
  const [designation, setDesignation] = useState([]);
  const [type, setType] = useState([]);
  const [status, setStatus] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dtypeRes = await axios.get(`${BASE_URL}/employee/Designation`);
        const designationData = dtypeRes.data.map(item => ({
          id: item.id,
          name: item.name,
        }));
        setDesignation(designationData);

        const typeRes = await axios.get(`${BASE_URL}/employee/Type`);
        const typeData = typeRes.data.map(item => ({
          id: item.id,
          name: item.name,
        }));
        setType(typeData);

        const statusRes = await axios.get(`${BASE_URL}/employee/Status`);
        const statusData = statusRes.data.map(item => ({
          id: item.id,
          name: item.name,
        }));
        setStatus(statusData);


        if (data) {
          setFormData({
            name: data.Name,
            email: data.Email,
            Mobile: data.Mobile,
            phone: data.Phone,
            address: data.Address,
            passport: data.Passport,
            nic: data.NIC,
            designation_id: designationData.find(d => d.name === data.Designation)?.id || '',
            employe_type_id: typeData.find(t => t.name === data.Type)?.id || '',
            idemployee: data.idEmp,
            status:statusData.find(s => s.name === data.Status)?.id || '',
          });
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const { name, email, Mobile, address, designation_id, employe_type_id } = formData;
    if (!name || !email || !Mobile  || !address || !designation_id || !employe_type_id) {
      return 'Please fill out all required fields.';
    }
    return '';
  };

  const updateCustomer = async () => {
    const validationError = validateForm();
    if (validationError) {
      setFormError(validationError);
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/employee/Update`, formData);
      console.log(response.data);
      onCustomerUpdated(); // Update the parent component
      handleClose(); // Close the dialog
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormError('');
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        Edit
      </MDButton>

      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
          <h3>Edit Employee</h3>
            {formError && (
              <Typography color="error" variant="body2">
                {formError}
              </Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
            
              <Grid item xs={3}>
                <TextField
                  label="Mobile"
                  name="Mobile"
                  type="tel"
                  value={formData.Mobile}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Phone"
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
               
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Designation"
                  name="designation_id"
                  select
                  value={formData.designation_id}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                >
                  <MenuItem value="">
                    <em>Select a Designation</em>
                  </MenuItem>
                  {designation.map((designationType) => (
                    <MenuItem key={designationType.id} value={designationType.id}>
                      {designationType.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Type"
                  name="employe_type_id"
                  select
                  value={formData.employe_type_id}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                >
                  <MenuItem value="">
                    <em>Select a Type</em>
                  </MenuItem>
                  {type.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Status"
                  name="status"
                  select
                  value={formData.status}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                >
                  <MenuItem value="">
                    <em>Select a Status</em>
                  </MenuItem>
                  {status.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <MDButton variant="contained" color="success" onClick={updateCustomer} fullWidth>
                  Update
                </MDButton>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditEmployee;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, TextField, Button, MenuItem, Typography, Divider,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid
} from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import NewCustomer from 'layouts/customer/newCustomer';

const QuotationForm = () => {
  const [formData, setFormData] = useState({
    quotation_no: '',
    customer_id: '',
    status: '1',
    create_date: new Date().toISOString().split('T')[0],
    status_date: new Date().toISOString().split('T')[0],
    net_price: '',
    tax: '',
    total: '',
    is_tax: 1,
    remarks: '',
    date: new Date().toISOString().split('T')[0],
  });

  const [tax, setTax] = useState({
    name: '',
    tax_rate: 0,
  });
  
  const [services, setServices] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [newService, setNewService] = useState({ id: '', quantity: 1, pricePerUnit: 0.00 });
  const [newItem, setNewItem] = useState({ id: '', quantity: 1, pricePerUnit: 0.00 });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [registeredCustomers, setRegisteredCustomers] = useState([]);
  const [open, setOpen] = useState(false);

  // Fetch data when the component mounts
  const fetchData = async () => {
    try {
      const [serviceRes, itemRes, customerRes, taxRes, quotationRes] = await Promise.all([
        axios.get(`${BASE_URL}/item/AllService`),
        axios.get(`${BASE_URL}/item/AllItem`),
        axios.get(`${BASE_URL}/customer/Allcustomers`),
        axios.get(`${BASE_URL}/quotation/tax`),
        axios.get(`${BASE_URL}/quotation/LastQuotationNo`)
      ]);

      setServices(serviceRes.data.map(service => ({
        id: service.iditem,
        unit: service.unit_name,
        name: service.name,
        price: service.price,
      })));

      setItems(itemRes.data.map(item => ({
        id: item.iditem,
        unit: item.unit_name,
        name: item.name,
        price: item.price,
      })));

      setRegisteredCustomers(customerRes.data);

      setTax(taxRes.data[0] || { name: 'Tax', tax_rate: 0 });

      const lastQuotationNumber = quotationRes.data[0].quotation_no;
      const nextQuotationNumber = String(parseInt(lastQuotationNumber) + 1);

      setFormData(prevFormData => ({
        ...prevFormData,
        quotation_no: nextQuotationNumber,
      }));

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle changes in form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Open and close modal
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle changes in service and item fields with common function
  const handleChangeCommon = (e, stateUpdater, itemsList) => {
    const { name, value } = e.target;
    let selectedItem = itemsList.find(item => item.id === parseInt(value));
    if (!selectedItem) selectedItem = { price: 0 };

    stateUpdater((prevState) => ({
      ...prevState,
      [name]: value,
      pricePerUnit: selectedItem.price,
      price: selectedItem.price * prevState.quantity || 0,
    }));
  };

  const handleServiceChange = (e) => handleChangeCommon(e, setNewService, services);
  const handleItemChange = (e) => handleChangeCommon(e, setNewItem, items);

  const handleQtyChange = (e, stateUpdater) => {
    const { name, value } = e.target;
    const newQuantity = parseFloat(value);

    if (!isNaN(newQuantity) && newQuantity >= 0) {
      stateUpdater((prevState) => ({
        ...prevState,
        [name]: newQuantity,
        price: prevState.pricePerUnit * newQuantity,
      }));
    }
  };

  const handleServiceQtyChange = (e) => handleQtyChange(e, setNewService);
  const handleItemQtyChange = (e) => handleQtyChange(e, setNewItem);

  // Handling registered customer changes
  const handleRegisteredCustomerChange = async (e) => {
    const customerId = e.target.value;
    const customer = registeredCustomers.find(c => c.idcustomer === customerId);

    if (customer) {
      setFormData({
        ...formData,
        customer_id: customer.idcustomer,
        name: customer.name,
        email: customer.email_address,
        phone: customer.mobile,
        address: customer.address,
        vehicleType: '',
        vehicleNo: '',
        date: formData.date,
        remarks: '',
      });
    }
  };

  const addService = () => {
    const service = services.find(s => s.id === parseInt(newService.id));
    if (service) {
      setSelectedServices([
        ...selectedServices,
        { ...service, quantity: newService.quantity, price: newService.pricePerUnit },
      ]);
      setNewService({ id: '', quantity: 1, pricePerUnit: 0.00 });
    }
  };

  const addItem = () => {
    const item = items.find(i => i.id === parseInt(newItem.id));
    if (item) {
      setSelectedItems([
        ...selectedItems,
        { ...item, quantity: newItem.quantity, price: newItem.pricePerUnit * newItem.quantity },
      ]);
      setNewItem({ id: '', quantity: 1, pricePerUnit: 0.00 });
    }
  };

  const removeService = (index) => {
    const updatedServices = selectedServices.filter((_, i) => i !== index);
    setSelectedServices(updatedServices);
  };

  const removeItem = (index) => {
    const updatedItems = selectedItems.filter((_, i) => i !== index);
    setSelectedItems(updatedItems);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const total = calculateTotal([...selectedServices, ...selectedItems]);
      const responseHeader = await axios.post(`${BASE_URL}/quotation/SaveHeader`, {
        ...formData,
        net_price: total,
        total: (parseFloat(total) + parseFloat(calculateTax(total))).toFixed(2),
        tax: tax.tax_rate,
      });

      const quotation_no = responseHeader.data;

      const detailsData = [
        ...selectedServices.map(service => ({
          quotation_no,
          item_id: service.id,
          item: service.name,
          quantity: service.quantity,
          price: service.pricePerUnit,
          total_price: service.quantity * service.pricePerUnit,
        })),
        ...selectedItems.map(item => ({
          quotation_no,
          item_id: item.id,
          item: item.name,
          quantity: item.quantity,
          price: item.pricePerUnit,
          total_price: item.quantity * item.pricePerUnit,
        })),
      ];

      if (quotation_no !== '') {
        await axios.post(`${BASE_URL}/quotation/SaveDetails`, { detailsData });
      }

      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  // Calculate total and tax
  const calculateTotal = (items = []) => items.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);
  const calculateTax = (total) => (total * tax.tax_rate).toFixed(2);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  const handleCustomerAdded = () => {
    fetchData();
    console.log('Customer added!');
  };

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        New Quotation
      </MDButton>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            <form onSubmit={handleSubmit}>
              <Typography variant="h4" gutterBottom>
                Quotation
              </Typography>
              <Grid container spacing={2}>
                
                <Grid item xs={10}>
                  <TextField
                    label="Select Customer"
                    name="customerId"
                    select
                    value={formData.customer_id || ''}
                    onChange={handleRegisteredCustomerChange}
                    fullWidth
                    margin="normal"
                    required
                  >
                    <MenuItem value="">
                      <em>Select a customer</em>
                    </MenuItem>
                    {registeredCustomers.map((customer) => (
                      <MenuItem key={customer.idcustomer} value={customer.idcustomer}>
                        {customer.name} | Address: {customer.address}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <NewCustomer onCustomerAdded={handleCustomerAdded} />
                </Grid>
              
                <Grid item xs={5}>
                  <TextField
                    label="Date"
                    name="date"
                    type="date"
                    value={formData.date}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Quotation No"
                    name="quotation_no"
                    value={formData.quotation_no}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Add Services
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    label="Service"
                    name="id"
                    select
                    value={newService.id}
                    onChange={handleServiceChange}
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="">
                      <em>Select a service</em>
                    </MenuItem>
                    {services.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {service.name} | {service.unit}:1 | €:{service.price}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Quantity"
                    name="quantity"
                    type="number"
                    value={newService.quantity}
                    onChange={handleServiceQtyChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDButton variant="contained" color="success" onClick={addService} fullWidth>
                    Add Service
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Add Equipments
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    label="Equipments"
                    name="id"
                    select
                    value={newItem.id}
                    onChange={handleItemChange}
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="">
                      <em>Select an Equipments</em>
                    </MenuItem>
                    {items.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name} | {item.unit}:1 | €:{item.price}
                        
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Quantity"
                    name="quantity"
                    type="number"
                    value={newItem.quantity}
                    onChange={handleItemQtyChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDButton variant="contained" color="success" onClick={addItem} fullWidth>
                    Add Equipments
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Selected Services and Equipments
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        
                      </TableHead>
                      <TableBody>
                      <TableRow>
                          <TableCell style={{ width: '30%' }}>Description</TableCell>
                          <TableCell style={{ width: '10%' }} align="right">Unit</TableCell>
                          <TableCell style={{ width: '10%' }} align="right">Quantity</TableCell>
                          <TableCell style={{ width: '20%' }} align="right">Price per Unit</TableCell>
                          <TableCell style={{ width: '20%' }} align="right">Total Price</TableCell>
                          <TableCell style={{ width: '10%' }} align="right"></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ width: '30%', backgroundColor: '#ffecf2', color: '#333' }}>Service</TableCell>                         
                        </TableRow>
                        {selectedServices.map((service, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {service.name}
                            </TableCell>
                            <TableCell align="right">{service.unit}</TableCell>
                            <TableCell align="right">{service.quantity}</TableCell>
                            <TableCell align="right">€ {service.price}</TableCell>
                            <TableCell align="right">€ {service.price * service.quantity}</TableCell>
                            <TableCell align="right">
                              <Button variant="contained" color="success" onClick={() => removeService(index)}>
                                X
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell style={{ width: '30%', backgroundColor: '#f3eeff', color: '#333' }}>Equipments</TableCell>                         
                        </TableRow>
                        {selectedItems.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {item.name}
                            </TableCell>
                            <TableCell align="right">{item.unit}</TableCell>
                            <TableCell align="right">{item.quantity}</TableCell>
                            <TableCell align="right">€ {item.pricePerUnit}</TableCell>
                            <TableCell align="right">€ {item.price}</TableCell>
                            <TableCell align="right">
                              <Button variant="contained" color="success" onClick={() => removeItem(index)}>
                                X
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell rowSpan={3} />
                          <TableCell colSpan={3}>Net</TableCell>
                          <TableCell align="right">€ {calculateTotal([...selectedServices, ...selectedItems])}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3}>{tax.name} {(tax.tax_rate * 100).toFixed(2)}%</TableCell>
                          <TableCell align="right">€ {calculateTax(calculateTotal([...selectedServices, ...selectedItems]))}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3}>Total</TableCell>
                          <TableCell align="right">€ {(parseFloat(calculateTotal([...selectedServices, ...selectedItems])) + parseFloat(calculateTax(calculateTotal([...selectedServices, ...selectedItems])))).toFixed(2)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <MDButton type="submit" variant="contained" color="success" fullWidth>
                    Save Quotation
                  </MDButton>
                </Grid>
              </Grid>
            </form>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuotationForm;
